import React, {useEffect, useState} from "react";
import {getStrapiMedia} from "../lib/media";
import toDataURL from "../lib/image-base-64";
import NextImage from "next/image";
import {StrapiImage} from "../types/strapi-types";
import {Property} from "csstype";
import ObjectFit = Property.ObjectFit;

interface ImageWithBlurProps {
    image?: StrapiImage;
    image_url?: string;
    className?: string;
    objectFit?: ObjectFit;
    conversion?: string;
    width?: string;
    height?: string;
    layout?: "fixed" | "fill" | "intrinsic" | "responsive" | "raw" | undefined;
}

const ImageWithBlur = ({className, conversion, image, width, height, objectFit = "cover", layout, ...props}: ImageWithBlurProps) => {
    const [imageData, setImageData] = useState<string | undefined>(undefined);
    const [src, setSrc] = useState<string>('');
    const mediaUrl = image ? getStrapiMedia(image, 'thumbnail') : props.image_url;
    useEffect(() => {
        if(image){
            conversion ? setSrc(getStrapiMedia(image, conversion)) : setSrc(getStrapiMedia(image))
        }else{
            setSrc(props.image_url || '')
        }
        toDataURL(mediaUrl, (dataUrl: string) => {
            setImageData(dataUrl);
        });
    }, []);

    return (
        <>
            {imageData && <NextImage
              className={className}
              src={src}
              objectFit={objectFit}
              width={width}
              height={height}
              blurDataURL={imageData}
              placeholder='blur'
              layout={layout}
            />}
        </>)
}

export default ImageWithBlur;
