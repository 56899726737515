import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownContent = ({children}) => {
  return (
    <ReactMarkdown className='prose prose-lg prose-li:m-0 prose-li:p-0 font-sans'>
      {children}
    </ReactMarkdown>
  )
}

export default MarkdownContent;