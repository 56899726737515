import React, {ReactNode} from "react";
import cls from "classnames";
import Link from 'next/link'

const sharedClasses = 'my-8 hover:bg-opacity-25 border-3 text-base py-2 px-10 font-bold ease-in-out duration-300'

export const LearnMoreButton = ({children, href=''}: { children: ReactNode, href?: string }) => {
    return (
      <Link href={href}>
        <button className={cls("text-white border-white hover:bg-white ", sharedClasses)}>
            {children}
        </button>
      </Link>
    );
}

export const CallToActionButton = ({children, route, className = ""}: { className?: string, route: string, children: ReactNode }) => {
    return (
        <Link href={route}>
            <a className={cls("inline-block text-gray-theme border-gray-theme hover:bg-gray-theme", sharedClasses, className)}>
                {children}
            </a>
        </Link>
    );
}

export const CustomColourButton = ({children, href,className = ""}: {href: string, className?:string, children: ReactNode }) => {
    return (
        <Link href={href}>
            <a className={cls("text-gray-theme hover:bg-gray-theme text-center", sharedClasses, className)}>{children}</a>
        </Link>
    );
}
