import {mdiAccountOutline, mdiChevronLeft, mdiChevronRight} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import Link from 'next/link';
import React, {useEffect} from 'react';
import Slider from 'react-slick';
import {TemporarySection} from '../types/page-types';
import {CustomColourDivider} from './banner/divider';
import {callToActionParagraphClasses, callToActionTitleTwoClasses} from './banner/title-classes';
import {CustomColourButton} from './buttons';
import CallToAction from './call-to-action';
import ImageWithBlur from './image-with-blur';
import MarkdownContent from './markdown-content';

const PreviousArrow = (props) => {
  const {className, style, onClick} = props;

  return (
    <button className={cls((className.replace('slick-prev', 'absolute top-1/2 -left-10 -mt-5')))} onClick={onClick}>
      {
        className.includes('slick-disabled') ?
          <Icon className="text-gray-400" path={mdiChevronLeft} size={2}/> :
          <Icon className="text-gray-theme hover:text-black" path={mdiChevronLeft} size={2}/>
      }
    </button>
  );
};

const NextArrow = (props) => {
  const {className, style, onClick} = props;

  return (
    <div className={cls((className.replace('slick-next', 'absolute top-1/2 -right-10 -mt-5')))} onClick={onClick}>
      {
        className.includes('slick-disabled') ?
          <Icon className="text-gray-400" path={mdiChevronRight} size={2}/> :
          <Icon className="text-gray-theme hover:text-black" path={mdiChevronRight} size={2}/>
      }
    </div>
  );
};
const GridCard = ({
  className,
  section,
  index,
  button,
  coloredTitle,
  images
}: { className: string, section: TemporarySection, index: number, button: boolean, coloredTitle?: string, images: boolean }) => {
  if (button) {
    return (
      <div className={cls('flex flex-col h-full lg:p-20 md:p-10 sm:p-20 p-10', className)}>
        <div className="w-full">
          {images && (<>{!section.image.data && section.isStaffMember ?
            <div className="bg-white rounded-full border ">
            <Icon className="text-gray-200 w-full h-full" path={mdiAccountOutline}/>
            </div>
            :
            <ImageWithBlur key={section.image.data?.id} className="rounded-full border w-full" conversion="medium" image={section.image}
            width="500" height="500"/>
          }</>)}
        </div>
        <h4 className={cls('font-bold -mb-2', section.color, coloredTitle && 'pt-6', section.secondaryTitleSize)}>{coloredTitle ? coloredTitle : <>0{index + 1}</>}</h4>
        <CustomColourDivider className={section.borderColor}/>
        <h3 className={cls(callToActionTitleTwoClasses)}>{section.title}</h3>
        <p className={cls(callToActionParagraphClasses, 'grow')}>{section.text}</p>
        <CustomColourButton href={section.link} className={cls(section.borderColor, 'w-52')}>
          {section.button_title ? section.button_title : 'Read more'}
        </CustomColourButton>
      </div>
    );
  } else if (section.link) {
    return (
      <Link href={section.link}>
        <a className={cls('flex flex-col ease-in-out duration-300 h-full lg:p-20 md:p-10 sm:p-20 p-10 hover:bg-opacity-25 hover:bg-gray-theme', className)}>

          <div className="w-full">
            {images && (<>{!section.image.data && section.isStaffMember ?
              <div className="bg-white rounded-full border ">
                <Icon className="text-gray-200 w-full h-full" path={mdiAccountOutline}/>
              </div>
              :
              <ImageWithBlur key={section.image.data?.id} className="rounded-full border w-full" conversion="medium" image={section.image}
                             width="500" height="500"/>
            }</>)}
          </div>

          <h4 className={cls('font-bold -mb-2', section.color, coloredTitle && 'pt-6', section.secondaryTitleSize)}>{coloredTitle ? coloredTitle : <>0{index + 1}</>}</h4>
          <CustomColourDivider className={section.borderColor}/>
          <h3 className={cls(callToActionTitleTwoClasses)}>{section.title}</h3>
          <p className={cls(callToActionParagraphClasses, 'grow')}>{section.text}</p>

        </a>
      </Link>
    );
  } else if (section.isStaffMember) {
    return (

      <div className={cls('flex flex-col ease-in-out duration-300 h-full lg:px-10 lg:py-20 md:p-10 sm:p-20 p-10 hover:bg-opacity-25 hover:bg-gray-theme', className)}>

        <div className="w-full">
          {images && (<>{!section.image.data && section.isStaffMember ?
            <div className="bg-white rounded-full border ">
              <Icon className="text-gray-200 w-full h-full" path={mdiAccountOutline}/>
            </div>
            :
            <ImageWithBlur key={section.image.data?.id} className="rounded-full border w-full" conversion="medium" image={section.image}
                           width="500" height="500"/>
          }</>)}
        </div>
        <h4 className={cls('font-bold -mb-2', section.color, coloredTitle && 'pt-6', section.secondaryTitleSize)}>{coloredTitle ? coloredTitle : <>0{index + 1}</>}</h4>
        <CustomColourDivider className={section.borderColor}/>
        <h3 className={cls(callToActionTitleTwoClasses)}>{section.title}</h3>
        <MarkdownContent>
          {section.text}
        </MarkdownContent>
      </div>

    );
  } else {
    return (
      <>
        <a className={cls('flex flex-col ease-in-out duration-300 h-full lg:p-20 md:p-10 sm:p-20 p-10 hover:bg-opacity-25 hover:bg-gray-theme', className)}>
          <div className="w-full">
          {images && (<>{!section.image.data && section.isStaffMember ?
              <div className="bg-white rounded-full border ">
                <Icon className="text-gray-200 w-full h-full" path={mdiAccountOutline}/>
              </div>
              :
              <ImageWithBlur key={section.image.data?.id} className="rounded-full border w-full" conversion="medium" image={section.image}
                             width="500" height="500"/>
            }</>)}
          </div>
          <h4 className={cls('font-bold -mb-2', section.color, coloredTitle && 'pt-6', section.secondaryTitleSize)}>{coloredTitle ? coloredTitle : <>0{index + 1}</>}</h4>
          <CustomColourDivider className={section.borderColor}/>
          <h3 className={cls(callToActionTitleTwoClasses)}>{section.title}</h3>
          <p className={cls(callToActionParagraphClasses, 'grow')}>{section.text.length > 250 ? section.text.substring(0, 250) + '...' : section.text}</p>

        </a>
      </>
    );
  }
};

const GridSection = ({gridSections, button = true, coloredTitle, images = true}: { gridSections: TemporarySection[], button?: boolean, coloredTitle?: string, images?:boolean }) => {
  const settings = {
    arrowClasses: '-mx-10',
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow/>,
    prevArrow: <PreviousArrow/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const grids: any = {
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
    4: 'xl:grid-cols-4 md:grid-cols-2',
  };
  if (gridSections.length == 0) {
    return null;
  }
  if (gridSections.length == 1) {
    let title = gridSections[0].topTitle ? gridSections[0].topTitle : gridSections[0].title;
    title = title.charAt(0).toUpperCase() + title.substring(1).toLowerCase();
    return <CallToAction call_to_action_title={title} call_to_action_secondary_title={gridSections[0].title}
                         call_to_action_paragraph={gridSections[0].text} call_to_action_media={gridSections[0].image} grayBg={true}
                         route={gridSections[0].link} text="Read more"/>;
  } else if (gridSections.length <= 4) {
    return (
      <div className={cls('w-full sm:grid block', grids[gridSections.length <= 4 ? gridSections.length : 4])}>
        {
          gridSections.map((section: TemporarySection, index: number) =>
            <GridCard className={index % 2 ? 'bg-gray-200' : 'bg-gray-100'}
                      section={section}
                      key={index}
                      index={index}
                      button={button}
                      images={images}
                      coloredTitle={section.title_two ? section.title_two : coloredTitle}
            />,
          )
        }
      </div>
    );
  } else {
    return (
      <div className="bg-gray-200 w-full grid-section-slider">
        <Slider className="mx-10 flex items-stretch" {...settings} >
          {
            gridSections.map((section: TemporarySection, index: number) =>
              <GridCard className={index % 2 ? 'bg-gray-200' : 'bg-gray-100'}
                        section={section}
                        key={index}
                        index={index}
                        images={images}
                        button={button}
                        coloredTitle={section.title_two ? section.title_two : coloredTitle}
              />,
            )
          }
        </Slider>
      </div>
    );
  }
};


export default GridSection;