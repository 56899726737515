import cls from 'classnames';
import React from 'react';
import {StrapiImage} from '../types/strapi-types';
import {CallToActionDividerLeft} from './banner/divider';
import {callToActionTitleOneClasses, callToActionTitleTwoClasses} from './banner/title-classes';
import {CallToActionButton} from './buttons';
import ImageWithBlur from './image-with-blur';
import MarkdownContent from './markdown-content';
import PageWidthConstraint from './pagewidthconstraint';

interface CTAProps {
  call_to_action_title: string,
  call_to_action_secondary_title: string,
  call_to_action_paragraph: string,
  call_to_action_media: StrapiImage,
  route?: string,
  text?: string,
}


const CallToActionInner = ({className, ...props}: CTAProps & { className?: string }) => {
  const sharedClasses = 'text-gray-theme w-full flex lg:flex-row flex-col';
  return (
    <PageWidthConstraint className={cls(sharedClasses, className)}>
      <div className="md:w-3/5">
        <h2 className={callToActionTitleOneClasses}>{props.call_to_action_title}</h2>
        <CallToActionDividerLeft/>
        <h3 className={callToActionTitleTwoClasses}>{props.call_to_action_secondary_title}</h3>
        <MarkdownContent>
          {props.call_to_action_paragraph}
        </MarkdownContent>
        {props.route && <CallToActionButton route={props.route}>
          {props.text}
        </CallToActionButton>}
      </div>
      <div className="md:w-1/2 md:pt-0 pt-10 mt-auto mb-auto">
        <div className="sm:w-100 w-lg:ml-28 lg:mr-0 ml-auto mr-auto relative">
          <ImageWithBlur key={props.call_to_action_media.data?.id} className="rounded-full border" conversion="large" image={props.call_to_action_media}
                         width="400" height="400"/>
          {/*<Icon*/}
          {/*    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-3 ml-auto mr-auto border-white rounded-full pr-4 py-5 pl-5"*/}
          {/*    path={mdiPlayOutline} size={4} color="white"/>*/}
        </div>
      </div>

    </PageWidthConstraint>
  );
};

const CallToAction = ({grayBg = false, ...props}: CTAProps & { grayBg?: boolean }) => {
  if (grayBg) {
    return (
      <div className="bg-gray-300 w-full">
        <CallToActionInner {...props} className="py-12"/>
      </div>
    );
  } else {
    return <CallToActionInner {...props} className="sm:py-12 py-10"/>;
  }
};

export default CallToAction;